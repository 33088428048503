import { UserIcon } from '@heroicons/react/24/outline'
import { formatDate } from 'utils'

export default function EventItem({ data, onClick }) {
    return (
        <div
            onClick={() => onClick(data)}
            className={`rounded-lg flex items-center p-4 flex-auto bg-dark-500`}>
            <div className="overflow-x-hidden">
                <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.endingPoint}
                </div>
                <div className="text-text text-xs capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {formatDate(data.date, 'dddd[,] DD [de] MMMM [del] YYYY')}
                </div>
                <div className="text-text text-xs capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    Creado por {data.user.name} / {data.user.chapter}
                </div>
                <div className="text-white text-xs whitespace-nowrap overflow-x-hidden text-ellipsis flex gap-2 items-center mt-1">
                    <UserIcon className="w-4" />
                    <span className="-mb-0.5">
                        {data.membersCount}{' '}
                        {data.membersCount === 1
                            ? 'miembro asistirá'
                            : 'miembros asistirán'}
                    </span>
                </div>
            </div>
        </div>
    )
}
