import React, { useContext, useRef } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
    EnvelopeIcon,
    LockClosedIcon,
    EyeIcon
} from '@heroicons/react/24/outline'
import { AccountAPI } from 'api'
import { UserContext } from 'context/UserContext'
import CoyoteProfileSVG from 'assets/coyote.svg'

export default function Signin() {
    const formRef = useRef()
    const { setState: setUserState } = useContext(UserContext)

    const onSignin = async ({ email, password }) => {
        const { data } = await AccountAPI.signin({ email, password })

        if (data.success) {
            const { user, token } = data

            return setUserState((prevState) => ({
                ...prevState,
                token,
                user
            }))
        }

        if (data.message === 'USER_DOESNT_EXIST') {
            formRef.current.setFieldError(
                'email',
                'El nombre de usuario no existe'
            )
        }

        if (data.message === 'WRONG_PASSWORD') {
            formRef.current.setFieldError(
                'password',
                'La contraseña no coincide. Intentalo nuevamente'
            )
        }
    }

    const onShowPassword = ($event) => {
        const input = $event.currentTarget.nextSibling

        if (input.type === 'password') {
            input.type = 'text'
        } else {
            input.type = 'password'
        }
    }

    const initialValues = {
        email: '',
        password: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Este campo es requerido')
            .email('Correo electrónico inválido'),
        password: Yup.string().required('Este campo es requerido')
    })

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <div className="text-center mb-10 ">
                <img
                    className="mx-auto opacity-90 w-28"
                    src={CoyoteProfileSVG}
                />
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Bienvenido Coyote
                </h1>
                <p>
                    Inicia sesión para llevar un registro de tus kilómetros
                    rodados y tus aportaciones
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={onSignin}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="inline-block text-sm mb-2 text-white">
                                    Correo electrónico
                                </label>
                                <div className="relative">
                                    <EnvelopeIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                            errors.email && touched.email
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <Field
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Escribe tu nombre de usuario"
                                        className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                            errors.email && touched.email
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="inline-block text-sm mb-2 text-white">
                                    Contraseña
                                </label>
                                <div className="relative">
                                    <LockClosedIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                            errors.password && touched.password
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <EyeIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 right-4 ${
                                            errors.password && touched.password
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                        onClick={onShowPassword}
                                    />
                                    <Field
                                        id="password"
                                        name="password"
                                        placeholder="Contraseña"
                                        type="password"
                                        className={`w-full rounded-lg py-2 border px-12 ${
                                            errors.password && touched.password
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="password"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Iniciar sesión
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
