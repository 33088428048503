import React, { Fragment, useEffect, useState } from 'react'
import { EventsAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'

import SkeletonItem from 'components/SkeletonItem'
import EventItem from 'components/EventItem'
import CalendarSVG from 'assets/calendar.svg'
import { formatDate } from 'utils'

export default function Events() {
    const params = useParams()
    const navigate = useNavigate()
    const [events, setEvents] = useState({ futureEvents: [], pastEvents: [] })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getFilteredEvents()
    }, [params.filter])

    const getFilteredEvents = async () => {
        try {
            setLoading(true)

            const { data } = await EventsAPI.get({
                filter: params.filter
            })

            setEvents(data.events)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onAction = () => {
        navigate('add')
    }

    const showEmptyState = () => {
        if (
            events.futureEvents?.length === 0 &&
            events.pastEvents?.length === 0
        )
            return true

        return false
    }

    const FILTER_ITEMS = [
        {
            label: 'Todos',
            tag: 'all',
            action: function () {
                navigate('/events/' + this.tag)
            }
        },
        {
            label: 'Capítulo',
            tag: 'chapter',
            action: function () {
                navigate('/events/' + this.tag)
            }
        },
        {
            label: 'Mis eventos',
            tag: 'own',
            action: function () {
                navigate('/events/' + this.tag)
            }
        }
    ]

    const onSelectEvent = (event) => {
        navigate(event._id)
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onAction}
                showAction={true}
                title="Eventos"
            />

            <div className="p-2 bg-dark-500 rounded-2xl flex text-center text-sm mb-10">
                {FILTER_ITEMS.map((menu) => (
                    <div
                        key={menu.tag}
                        className={`w-1/2 rounded-xl py-2 ${
                            params.filter === menu.tag
                                ? 'bg-dark-400 text-white'
                                : ''
                        }`}
                        onClick={() => menu.action.call(menu)}>
                        {menu.label}
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-10">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {showEmptyState() ? (
                            <div className="text-center flex flex-col items-center mt-20">
                                <img src={CalendarSVG} className="w-16 mb-4" />
                                <div>
                                    <div className="text-white mb-2">
                                        No hay eventos recientes
                                    </div>
                                    <div className="text-text text-sm">
                                        Los eventos creados por otros miembros
                                        del club aparecerán aquí y podrás unirte
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="flex flex-col gap-10">
                                    {events.futureEvents?.map(
                                        ({ events, yearMonth }) => (
                                            <div
                                                key={yearMonth}
                                                className="flex flex-col gap-6">
                                                <div className="flex justify-between text-xs uppercase font-semibold">
                                                    <div>
                                                        Próximos Eventos{' '}
                                                        {formatDate(
                                                            yearMonth,
                                                            'MMMM YYYY'
                                                        )}
                                                    </div>
                                                </div>
                                                {events.map((event) => (
                                                    <EventItem
                                                        key={event._id}
                                                        data={event}
                                                        onClick={onSelectEvent}
                                                    />
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="flex flex-col gap-10 opacity-40">
                                    {events.pastEvents?.map(
                                        ({ events, yearMonth }) => (
                                            <div
                                                key={yearMonth}
                                                className="flex flex-col gap-6">
                                                <div className="flex justify-between text-xs uppercase font-semibold">
                                                    <div>
                                                        Últimos Eventos{' '}
                                                        {formatDate(
                                                            yearMonth,
                                                            'MMMM YYYY'
                                                        )}
                                                    </div>
                                                </div>
                                                {events.map((event) => (
                                                    <EventItem
                                                        key={event._id}
                                                        data={event}
                                                        onClick={onSelectEvent}
                                                    />
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
